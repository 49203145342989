//
// Palette
//

$text_color: #333;
$link_color: #06c;

//feature site colours
$green:#4F6D2D;
$purple:#774055; 

//colour for backgrounds
$grey: #F2F2F2;
$darker_grey:#E5E5E5;