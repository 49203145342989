//
// Typography
//

// Default styles for HTML5 block level elements
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

figure {
    margin: 0;
}

// Document body
body {
    color: $text_color;
    line-height: 1.5;
    margin: 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    font-family:$body_font;
}

// Links
a {
    color: $link_color;
}

a:focus {
    outline: 1px dotted;
}

// Basic typography
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading_font;
    font-size: 1em;
    line-height: 1.25;
    margin: 1em 0 0 0;
    page-break-after: avoid;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 40px;
    color:$green;
    font-weight:400;
    margin-top:0;
    margin-bottom:0.5em;

    @include media-query(micro) {
        font-size:48px;
    }
}

h3 {
    font-size:30px;

    @include media-query(micro) {
        font-size: 40px;
    }
}

h4 {
    font-size:2.3em;
}

hr {
    border: 0;
    border-top: 1px solid;
    margin: 1em 0;
}

blockquote {
    margin: 1em 2em;
}

p {
    margin: 1em 0;
}

pre {
    margin: 1em 0;
    white-space: pre-wrap;
}

address,
cite,
dfn,
em,
i,
var {
    font-style: italic;
}

b,
strong {
    font-weight: bold;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 0.875em;
}

pre code,
pre kbd,
pre samp {
    font-size: 1em;
}

small {
    font-size: 0.75em;
}

sub,
sup {
    font-size: 0.75em;
    line-height: 0;
}

sub {
    vertical-align: sub;
}

sup {
    vertical-align: super;
}

// Lists
dl {
    margin: 1em 0;
}

dd {
    margin-left: 2em;
}

ol,
ul {
    margin: 1em 0;
    padding-left: 2em;
}

ol li {
    list-style: decimal outside none;
}

ol li li {
    list-style: lower-alpha outside none;
}

ol li li li {
    list-style: lower-roman outside none;
}

ul li {
    list-style: disc outside none;
}

ul li li {
    list-style: circle outside none;
}

// Images
img {
    border: 0;
    height: auto;
    max-width: 100%;
}

// Tables
table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1em auto;
}

td,
th {
    padding: 0.25em 0.5em;
    text-align: left;
}
