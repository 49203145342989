.main-full {
    .home-banner {
        background-image: url("../../content/images/banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;  
        background-position-x: calc(85% + 10em);      
        margin-top:10px;
        position:relative;

        @include media-query(nano) {
            background-position-x: calc(85% + 6em);
        }

        @include media-query(kilo) {
            background-position-x: calc(85% + 2em);
        }

        @include media-query(mega) {
            background-position-x: center;
        }

        @include media-query(terra) {
            height:460px;
        }

        h2 {
            color:#ffffff;
            background-color:$purple;
            display:inline-block;
            margin:19rem 0 0;
            padding:0.5rem;
            font-size:22px;

            @include media-query(nano) {
                font-size:30px;
                margin-top:18.5rem;
            }

            @include media-query(micro) {
                font-size:35px;
            }

            @include media-query(normal) {
                font-size:40px;
                margin-top:15.5rem;
            }
        }
    }

    .home-services {
        margin: 0 auto 7em;

        @include media-query(milli) {
            display: flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            align-items:stretch;
        }

        .home-services-column {
            position:relative;
            
            @include media-query(milli) {
                display:inline-block;
                width:calc(50% - 2rem);                
            }

            @include media-query(normal) {
                width:calc(50% - 2.5rem);
            }

            @include media-query(mega) {
                width:calc(50% - 3rem);
            }

            &.green {
                margin-top:6em;
                
                li:before {
                    @include leaf-points('green');
                }
            }

            &.purple {
                margin-top:8em;

                @include media-query(milli) {
                    float:right;
                    margin-top:6em;
                }

                li:before {
                    @include leaf-points('purple');
                }
            }  

            h3 {
                @include header-box();
                font-size:20px;

                @include media-query(nano) {
                    font-size: 30px;
                    top:-25px;
                }

                @include media-query(micro) {
                    font-size:35px;
                    top:-30px;
                }

                @include media-query(milli) {
                    font-size:25px;
                    top:-25px;
                }

                @include media-query(normal) {
                    font-size:30px;
                    top:-27px;
                }

                @include media-query(kilo) {
                    font-size:35px;
                    top:-30px;
                }

                @include media-query(mega) {
                    font-size:40px;
                }

                &.green {
                    background-color:$green;
                }

                &.purple {
                    background-color:$purple;
                }
            }

            ul {
                padding:0;
                margin: 1em 0 2em;

                li {
                    list-style-type:none;
                    position:relative;
                    padding-left:2em;
                    margin-bottom:0.5em;
                }
            }

            .link-button-wrapper {
                position:absolute;
                right:0;
                
                @include media-query(milli) {
                    bottom:-2rem;
                }

                .link-button {
                    @include arrow-button(0.6em 2.6em 0.6em 0.9em);
                    position:relative;
                    
                    &:hover {
                        text-decoration:underline;
                    }

                    &.purple {
                        color:$purple;

                        &:after {
                            @include arrow('purple');
                        }
                    }

                    &.green {
                        color:$green;

                        &:after {
                            @include arrow('green');
                        }
                    }
                }
            }
        }
    }

    .home-testimonials {
        background-image: url("../../content/images/background-logo.png");
        background-color:rgba(79,109,45,0.9);
        background-position:right bottom;
        background-repeat:no-repeat;
        color:#ffffff;

        h3 {
            color:$green;
            background-color:#ffffff;
            display:inline-block;
            padding:0.4em 0.6em;
            font-weight:400;
            margin:3rem 0 1rem;

            @include media-query(micro) {
                font-size:30px;
            }

            @include media-query(milli) {
                font-size:40px;
                margin: 3rem 0 2rem;
            }

            @include media-query(kilo) {
                margin: 4rem 0 3rem;
            }
        }

        .image {
            @include media-query(milli) {
                display:inline-block;
                background-image: url("../../content/images/testimonial-image.png");
                background-size:contain;
                background-repeat:no-repeat;
                height:160px;
                width:160px;
                position:absolute;
                top:11rem;
                left:-0.5rem;
            }

            @include media-query(normal) {
                top:12rem;
                left:1rem;
            }

            @include media-query(kilo) {
                left:1rem;
                top:14.8rem;
            }

            @include media-query(mega) {
                top:15rem;
            }

            @include media-query(giga) {
                left:1rem;
                top:15rem;
            }
        }

        blockquote {
            color:#ffffff;
            font-style:italic;
            font-size:18px;
            max-width:80%;
            margin:2rem auto 1rem;
            position:relative;

            @include media-query(nano) {
                font-size:20px;
            }

            @include media-query(milli) {
                max-width:70%;
                margin:1em 1em 1em 10em;
            }

            @include media-query(normal) {
                font-size:24px;
                max-width:85%;
            }

            @include media-query(kilo) {
                max-width:75%;
            }

            @include media-query(mega) {
                font-size:28px;
                max-width:65%;
            }

            @include media-query(giga) {
                max-width:65%;
                margin:1em 1em 1em 11em;
            }

            &:before {
                position:absolute;
                font-size:4em;
                font-weight:bold;
                color: #ffffff;
                opacity:0.2;
                content: "\201C";
                top: -0.5em;
                left: -2.5rem;
                font-family:"bitter";
                font-style:normal;

                @include media-query(nano) {
                    left:-3rem;
                }

                @include media-query(milli) {
                    top:-1.6rem;
                }

                @include media-query(normal) {
                    left:-4rem;
                    top:-1.8rem;
                }

                @include media-query(mega) {
                    left:-5rem;
                    top:-2.1rem; 
                }
            }

            &:after {
                position:absolute;
                font-size:4em;
                font-weight:bold;
                color: #ffffff;
                opacity:0.2;
                content: "\201D";
                right: -0.8rem;
                bottom: -5rem;
                font-family:"bitter";
                font-style:normal;

                @include media-query(nano) {
                    right:1rem;
                }

                @include media-query(micro) {
                    right:-1rem;
                }

                @include media-query(normal) {
                    right:-1.5rem;
                }

                @include media-query(mega) {
                    right:-2.4rem;  
                }
            }
        }

        .name {
            display:inline-block;
            float:right;
            margin-top:1em;
            margin-right:2em;

            @include media-query(micro) {
                margin-right:20%;
            }

            @include media-query(normal) {
                margin-right:15%;
            } 

            @include media-query(mega) {
                margin-right:12%;
            }

            &:before {
                display:inline-block;
                content:"-";
                margin-right:0.1em;
            }
        }

        .buttons {
            display:inline-block;
            text-align:center;
            padding: 2.5rem 0 2rem;

            @include media-query(micro) {
                display:block;
                text-align:right;
                padding:5rem 0 3rem;
            }

            @include media-query(milli) {
                padding:5rem 0 4rem;
            }

            @include media-query(kilo) {
                padding:6rem 0 5rem;
            }

            a {
                @include arrow-button(0.4rem);
                margin: 0 2rem 2rem;

                @include media-query(nano) {
                    padding:0.6em 0.9em;
                    margin: 0 4rem 2rem;
                }

                @include media-query(micro) {
                    margin: 0 0 0 2rem;
                }

                &.green {
                    color:$green;
                    background-color:#ffffff;
                    border-color:#ffffff;
                }

                &.white {
                    color:#ffffff;
                    border-color:#ffffff;
                }

                &:hover {
                    text-decoration:underline;
                }
            }
        }
    }

    .home-community {
        h3 {
            color:#ffffff;
            background-color:$purple;
            display:inline-block;
            padding:0.4em 0.6em;
            font-weight:400;
            font-size:25px;
            margin:2em 0 1em;

            @include media-query(nano) {
                font-size:30px;
            }

            @include media-query(milli) {
                font-size:40px;
                margin:4rem 0 2rem;
            }

            @include media-query(normal) {
                margin: 4rem 0;
            }
        } 

        .column {
            @include media-query(normal) {
                display:inline-block;
                width:calc(50% - 2rem);
                vertical-align:top;
            }

            @include media-query(mega) {
                width:calc(50% - 1.5rem);
            }
        }  

        .home-community-text {
            p {
                margin-top:0;
            }

            a {
                color:$purple;
                text-decoration: none;

                &:hover {
                    text-decoration:underline;
                }
            }

            ul {
                padding-left:1em;
                li {
                    list-style-type:none;
                    position:relative;
                    padding-left:2em;
                    margin-bottom:0.5em;

                    &:before {
                        @include leaf-points('purple');
                    }
                }
            }

            .button-link {
                @include arrow-button(0.6em 2.6em 0.6em 0.9em);
                color:$green;
                float:right;
                margin-top:1em;
                position:relative;

                &:hover {
                    text-decoration:underline;
                }

                &:after {
                    @include arrow('green');
                }
            }
        }

        .home-community-gallery {
            margin-top:7em;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-evenly;
            align-items:flex-start;

            @include media-query(micro) {
                justify-content:space-around;
            }

            @include media-query(normal) {
                margin-top:0;
                float:right;
            }

            a {
                width:calc(50% - 1rem);
                margin:0.125rem 0;

                @include media-query(micro) {
                    margin:0.25rem 0;
                }

                @include media-query(mega) {
                    margin: 0.5rem 0;
                    width:calc(50% - 1.5rem);
                }

                img {
                    width:100%;
                }
            }
        }
    }
}