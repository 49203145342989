.footer {
    margin-top:3em;

    &:before {
        display:block;
        content: "";
        background-image:url("../../content/images/forest_resized.png");
        background-position-y:bottom;
        background-repeat:repeat-x;
        height:130px;
        width:100%;
        opacity:0.05;
    }

    ul {
        padding:0;

        a {
            text-decoration:none;
            color:inherit;
            
            &:hover {
                text-decoration:underline;
            } 
        }
    }

    .columns-footer {
        background-color:$grey;        

        ul {
            li {
                list-style-type:none;
            }
        }

        .column {
            color:#000000;

            @include media-query(normal){
                display:inline-block;
                width:33%;
                vertical-align: top;
            }
            
            h4 {
                display:inline-block;
                color:#ffffff;
                font-size:1.5em;
                font-weight:400;

                @include media-query(normal) {
                    font-size:28px;
                }
            }
        }

        .column-services { 
            h4 {
                background-color:$purple;
                padding:10px 20px;
            }
                
            li {
                position:relative;
                padding-left:2em;

                &:before {
                    @include leaf-points('purple');
                }
            }
        }

        .column-contact {
            margin-bottom:3em;

            @include media-query(normal) {
                margin-bottom:0;
            }

            h4 {
                background-color:$green;
                padding:10px 15px;
            }

            li {
                position:relative;
                margin-left:35px;

                @include media-query(normal) {
                    margin-left:55px;
                }

                &:before {
                    display:inline-block;
                    content: "";
                    background-size:cover;
                    height:20px;
                    width:20px;
                    position:absolute;
                    left:-2.3rem;
                    top:0.2rem;                    
                }

                &.address:before {
                    background-image:url('../../content/images/map-marker.png');
                }

                &.email:before {
                    background-image:url('../../content/images/email.png');
                }

                &.phone:before {
                    background-image:url('../../content/images/phone.png');
                }
            }
        }

        .column-facebook { 
            .fb_iframe_widget {
                margin-bottom:5em;

                @include media-query(normal) {
                    margin-top:2.3em;
                }
            }
        }
    }

    .lower-footer {
        background-color:$darker_grey;
        height:14rem;
        text-align:center;

        @include media-query(micro) {
            height:12rem;
        }

        ul {
            margin:auto;
            padding-top:2em;
            max-width:500px;

            @include media-query(micro) {
                padding-top:60px;
            }

            li {
                list-style-type:none;
                display:block;

                @include media-query(micro) {
                    display:inline-block;
                }

                &:not(:last-child) {
                    &:after {
                        display:inline-block;
                        content:"·";
                        margin:0 0.1em 0 0.3em;
                    }
                }
            }
        }
    }
}