//
// Global settings
//

// Box model settings
* {
    box-sizing: border-box;
}

//leaf bullet points
@mixin leaf-points($colour) {
    display:inline-block;
    content: "";
    position:absolute;
    left:0;
    top:3px;
    background-size:cover;
    height:20px;
    width:20px;

    @if $colour == 'green' {
        background-image:url("../../content/images/leaf-green.png");
    }
    @if $colour == 'purple' {
        background-image:url("../../content/images/leaf-purple.png");
    }
}

//a header box positioned above and to the left of an image box
@mixin header-box() {
    color:#ffffff;
    display:inline-block;
    position:absolute;
    margin:0;
    padding:10px;
    left:-25px;
    top:-20px;
    font-weight:400;

    @include media-query(milli) {
        top:-25px;
    }

    @include media-query(kilo) {
        left:-30px;
    }

    @include media-query(mega) {
        padding:10px 18px; 
        top:-30px;      
    }
}

//link buttons including a right pointing arrow
@mixin arrow-button($padding: 0.6em 0.9em) {
    font-weight:700;
    display:inline-block;
    border:2px solid;
    padding:$padding;
    border-radius:5px;
    text-decoration:none;
    text-transform:uppercase;
}

//the arrow is included as a pseudo element
@mixin arrow($colour) {
    display:inline-block;
    content:" ";
    height:24px;
    width:24px;
    background-size:contain;
    vertical-align:middle;
    margin-left:8px;
    position:absolute;

    @if $colour == 'green' {
        background-image:url("../../content/images/arrow-green.png");
    }
    @if $colour == 'purple' {
        background-image:url("../../content/images/arrow-purple.png");
    }
}
