.grid-wrap {
    margin-left:25px;
    @include media-query(kilo) {
        margin-left: 30px;
    }
}

.nav-grid {
    margin:0 auto 4em;

    @include media-query(micro){
        display: flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:stretch; 
    }

    .nav-item {
        position:relative;
        margin-top:6em;
        margin-bottom:2em;

        @include media-query(micro){
            display:inline-block;
            width:calc(50% - 2.5rem);
        }

        @include media-query(kilo){
            width:calc(33% - 2.6rem);
        }

        h3 {
            @include header-box();
            font-size:1.1em;

            @include media-query(nano) {
                font-size:28px;
            }

            @include media-query(micro) {
                font-size:1.1em;
            }

            @include media-query(milli) {
                font-size:1.4em;
            }

            @include media-query(normal) {
                font-size:24px;
            }

            @include media-query(kilo) {
                font-size:22px;
            }

            @include media-query(mega) {
                padding:15px 20px;
            }

            @include media-query(giga) {
                font-size:28px;
            }

            a {
                text-decoration:none;
                color:#ffffff;

                &:hover {
                    text-decoration:underline;
                }
            }
        }

        img {
            display:block;
        }

        &.top {
            margin-top:2em;
        }

        &.top-2 {
            @include media-query(micro) {
                margin-top:2em;
            }
        }

        &.top-3 {
            @include media-query(kilo) {
                margin-top:2em;
            }
        }

        .link-button-wrapper {
            position:absolute;
            right:0;
            bottom:-50px;

            .link-button {
                @include arrow-button(0.6em 2.6em 0.6em 0.9em);
                position:relative;

                &:hover {
                    text-decoration:underline;
                }
            }
        }

        &.purple {
            h3 {
                background-color:$purple;
            }

            .link-button {
                border:2px solid $purple;
                color:$purple;
                
                &:after {
                    @include arrow('purple');
                }
            }

            &.even {
            //if the box is in an even row, it needs to be green when the boxes are displayed two per row at middle sizes
                @include media-query(micro) {
                    h3 {
                        background-color:$green;
                    }

                    .link-button {
                        border:2px solid $green;
                        color:$green;
                
                        &:after {
                            @include arrow('green');
                        }
                    }
                }

                //and then back to being purple when the boxes go to three per row.
                @include media-query(kilo) {
                    h3 {
                        background-color:$purple;
                    }

                     .link-button {
                        border:2px solid $purple;
                        color:$purple;
                
                        &:after {
                            @include arrow('purple');
                        }
                    }
                }
            }
        }

        &.green {
            h3 {
                background-color:$green;
            }

            .link-button {
                border: 2px solid $green;
                color:$green;

                &:after {
                    @include arrow('green');
                }
            }

            &.even {
            //if the box is in an even row, it needs to be purple when the boxes are displayed two per row at middle sizes
                @include media-query(micro) {
                    h3 {
                        background-color:$purple;
                    }

                    .link-button {
                        border:2px solid $purple;
                        color:$purple;
                
                        &:after {
                            @include arrow('purple');
                        }
                    }
                }

                //and then back to being green when the boxes go to three per row.
                @include media-query(kilo) {
                    h3 {
                        background-color:$green;
                    }

                     .link-button {
                        border:2px solid $green;
                        color:$green;
                
                        &:after {
                            @include arrow('green');
                        }
                    }
                }
            }            
        }
    }
}