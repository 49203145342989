@charset "UTF-8";
* {
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

figure {
  margin: 0; }

body {
  color: #333;
  line-height: 1.5;
  margin: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  font-family: "Source Sans Pro", sans-serif; }

a {
  color: #06c; }

a:focus {
  outline: 1px dotted; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bitter", serif;
  font-size: 1em;
  line-height: 1.25;
  margin: 1em 0 0 0;
  page-break-after: avoid; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 40px;
  color: #4F6D2D;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5em; }
  @media all and (min-width: 640px) {
    h2 {
      font-size: 48px; } }

h3 {
  font-size: 30px; }
  @media all and (min-width: 640px) {
    h3 {
      font-size: 40px; } }

h4 {
  font-size: 2.3em; }

hr {
  border: 0;
  border-top: 1px solid;
  margin: 1em 0; }

blockquote {
  margin: 1em 2em; }

p {
  margin: 1em 0; }

pre {
  margin: 1em 0;
  white-space: pre-wrap; }

address,
cite,
dfn,
em,
i,
var {
  font-style: italic; }

b,
strong {
  font-weight: bold; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 0.875em; }

pre code,
pre kbd,
pre samp {
  font-size: 1em; }

small {
  font-size: 0.75em; }

sub,
sup {
  font-size: 0.75em;
  line-height: 0; }

sub {
  vertical-align: sub; }

sup {
  vertical-align: super; }

dl {
  margin: 1em 0; }

dd {
  margin-left: 2em; }

ol,
ul {
  margin: 1em 0;
  padding-left: 2em; }

ol li {
  list-style: decimal outside none; }

ol li li {
  list-style: lower-alpha outside none; }

ol li li li {
  list-style: lower-roman outside none; }

ul li {
  list-style: disc outside none; }

ul li li {
  list-style: circle outside none; }

img {
  border: 0;
  height: auto;
  max-width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1em auto; }

td,
th {
  padding: 0.25em 0.5em;
  text-align: left; }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }

.wrap::after {
  clear: both;
  content: "";
  display: block; }

.wrap {
  max-width: 1280px;
  margin: 0 1.5rem; }
  @media all and (min-width: 640px) {
    .wrap {
      margin: 0 2.5rem; } }
  @media all and (min-width: 800px) {
    .wrap {
      margin: 0 3.5rem; } }
  @media all and (min-width: 960px) {
    .wrap {
      margin: 0 2.5rem; } }
  @media all and (min-width: 1380px) {
    .wrap {
      margin: auto; } }
  .wrap.position-wrap {
    position: relative; }

.content .main-full {
  display: block;
  width: 100%;
  margin-top: 4em; }
  .content .main-full.home {
    margin-top: 0; }

.content .main {
  margin-top: 4em; }
  @media all and (min-width: 960px) {
    .content .main {
      display: inline-block;
      width: calc(65% - 1.5rem);
      vertical-align: top; } }
  @media all and (min-width: 1280px) {
    .content .main {
      width: calc(65% - 2.5rem); } }

.content .sidebar {
  margin-top: 4em; }
  @media all and (min-width: 960px) {
    .content .sidebar {
      display: inline-block;
      width: calc(35% - 1.5rem);
      vertical-align: top;
      margin-top: 10.5em;
      float: right; } }
  @media all and (min-width: 1280px) {
    .content .sidebar {
      width: calc(35% - 2.5rem); } }

button,
input,
select,
textarea {
  border-radius: 0;
  font: inherit;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"] {
  -webkit-appearance: none; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-ms-clear {
  display: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

input:focus,
textarea:focus {
  outline: 0; }

:invalid {
  box-shadow: none; }

.header .upper-header {
  height: 89px;
  position: relative;
  text-align: right; }
  @media all and (min-width: 640px) {
    .header .upper-header {
      text-align: center; } }
  @media all and (min-width: 960px) {
    .header .upper-header {
      text-align: right; } }
  .header .upper-header:after {
    content: "";
    position: absolute;
    background-image: url("../../content/images/forest_resized.png");
    background-repeat-y: no-repeat;
    background-position-y: bottom;
    opacity: 0.05;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .header .upper-header .logo {
    display: block;
    position: absolute;
    top: 15px;
    z-index: 2;
    text-align: left;
    left: 5px; }
    @media all and (min-width: 480px) {
      .header .upper-header .logo {
        top: 8px;
        left: inherit; } }
    @media all and (min-width: 640px) {
      .header .upper-header .logo {
        top: -6px; } }
    .header .upper-header .logo img {
      width: 70%; }
      @media all and (min-width: 480px) {
        .header .upper-header .logo img {
          width: 80%; } }
      @media all and (min-width: 640px) {
        .header .upper-header .logo img {
          width: auto; } }
  .header .upper-header ul {
    margin: 0;
    padding: 0.5rem 0; }
    @media all and (min-width: 960px) {
      .header .upper-header ul {
        padding-top: 1.5em; } }
    .header .upper-header ul li {
      display: block;
      font-size: 21px; }
      @media all and (min-width: 960px) {
        .header .upper-header ul li {
          display: inline-block; } }
      @media all and (min-width: 960px) {
        .header .upper-header ul li:first-of-type {
          position: relative;
          margin-right: 1rem; } }
      @media all and (min-width: 960px) {
        .header .upper-header ul li:first-of-type:after {
          display: inline-block;
          content: "·";
          position: absolute;
          right: -0.8rem;
          top: 0.1rem; } }
      .header .upper-header ul li a {
        color: #2f411b; }

.header .nav {
  background-color: #4F6D2D;
  height: auto; }
  @media all and (min-width: 960px) {
    .header .nav {
      height: 60px; } }
  .header .nav .nav-hidden {
    display: none; }
    @media all and (min-width: 960px) {
      .header .nav .nav-hidden {
        display: block; } }
  .header .nav #nav-toggle {
    padding: 0.5em 0;
    font-size: 1.1em; }
    @media all and (min-width: 960px) {
      .header .nav #nav-toggle {
        display: none; } }
  .header .nav ul {
    padding: 0;
    margin: 0; }
    @media all and (min-width: 960px) {
      .header .nav ul {
        float: right; } }
    .header .nav ul li {
      position: relative;
      display: block;
      background-color: #4F6D2D;
      padding-bottom: 0.5em;
      font-size: 1.1em; }
      @media all and (min-width: 800px) {
        .header .nav ul li {
          margin-left: 0.5em; } }
      @media all and (min-width: 960px) {
        .header .nav ul li {
          margin-left: 1em;
          font-size: 1em;
          padding: 0;
          display: inline-block; } }
      @media all and (min-width: 1120px) {
        .header .nav ul li {
          margin-left: 2em; } }
      .header .nav ul li:before {
        content: "";
        border: solid #ffffff 1px;
        display: inline-block;
        width: 40%;
        position: absolute;
        right: 0; }
        @media all and (min-width: 640px) {
          .header .nav ul li:before {
            right: 30%; } }
        @media all and (min-width: 960px) {
          .header .nav ul li:before {
            display: none; } }
      @media all and (min-width: 960px) {
        .header .nav ul li.active:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: calc(50% - 8px);
          margin: auto;
          border-style: solid;
          border-width: 0 7px 8px 7px;
          border-color: transparent transparent #ffffff transparent; } }
  .header .nav a {
    color: #ffffff;
    font-weight: 700;
    display: block;
    text-decoration: none;
    text-align: right;
    padding-top: 0.5em; }
    .header .nav a#menu-toggle {
      padding: 0; }
    @media all and (min-width: 640px) {
      .header .nav a {
        text-align: center; } }
    @media all and (min-width: 960px) {
      .header .nav a {
        padding: 1.2em 0;
        height: 60px; } }

.header li {
  list-style-type: none; }

.header a {
  text-decoration: none; }
  .header a:hover {
    text-decoration: underline; }

.path {
  margin-top: 1em;
  margin-left: 7em; }
  @media all and (min-width: 480px) {
    .path {
      margin-left: 10em; } }
  @media all and (min-width: 640px) {
    .path {
      margin-left: 13em; } }
  .path a {
    color: #774055;
    text-decoration: none; }
    .path a:hover {
      text-decoration: underline; }

.main h3 {
  color: #774055;
  font-weight: 400;
  margin: 1em 0 0.5em; }
  @media all and (min-width: 1120px) {
    .main h3 {
      margin: 0.75em 0 0.5em; } }

.main img {
  margin: 1em 0; }

.main a {
  text-decoration: none;
  color: #774055;
  display: inline;
  margin: 1em 0; }
  .main a:hover {
    text-decoration: underline; }

.main .image-block img {
  width: 100%; }
  @media all and (min-width: 640px) {
    .main .image-block img {
      width: calc(50% - 1em); } }
  .main .image-block img:last-of-type {
    float: right; }

.main .person {
  text-align: center; }
  @media all and (min-width: 800px) {
    .main .person {
      margin: 2em 0 3em; } }
  @media all and (min-width: 960px) {
    .main .person {
      margin: 3em 0; } }
  .main .person .person-image {
    margin: 3em 0;
    text-align: center;
    position: relative;
    display: inline-block; }
    @media all and (min-width: 800px) {
      .main .person .person-image {
        width: 25%;
        max-width: 200px;
        margin: 1rem 2rem 0 0;
        float: left;
        text-align: left; } }
    .main .person .person-image img {
      margin: 0; }
    .main .person .person-image:before {
      content: " ";
      display: inline-block;
      width: calc(100% + 1rem);
      height: calc(98% + 1rem);
      border-radius: 50%;
      position: absolute;
      top: -0.55rem;
      bottom: 0;
      left: -0.47rem;
      right: 0;
      z-index: -1;
      background-color: #ffffff;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4); }
  .main .person .person-profile {
    text-align: left; }
    @media all and (min-width: 800px) {
      .main .person .person-profile {
        display: inline-block;
        width: calc(70% - 0.5em);
        vertical-align: top; } }
    .main .person .person-profile p:first-of-type {
      font-size: 21px; }
    .main .person .person-profile h4 {
      display: inline-block;
      color: #ffffff;
      font-weight: 400;
      font-size: 28px;
      padding: 0.6rem 1.2rem;
      margin: 0; }
      .main .person .person-profile h4.green {
        background-color: #4F6D2D; }
      .main .person .person-profile h4.purple {
        background-color: #774055; }

.main .contact-form {
  margin-top: 1em; }
  .main .contact-form .field {
    margin-bottom: 10px; }
    .main .contact-form .field .text-label {
      display: inline-block;
      width: 100px;
      color: #774055;
      font-weight: 700; }
    .main .contact-form .field .text-input {
      margin-left: 10px;
      width: calc(70% - 10px);
      margin-bottom: 10px; }
    .main .contact-form .field.submit {
      margin-top: 20px; }
    .main .contact-form .field textarea {
      height: 100px; }
    .main .contact-form .field .button {
      color: #4F6D2D;
      background-color: #ffffff;
      font-weight: 700;
      display: inline-block;
      border: 2px solid;
      padding: 0.6em 0.9em;
      border-radius: 5px;
      text-decoration: none;
      text-transform: uppercase; }
      .main .contact-form .field .button:hover {
        text-decoration: underline; }

.main .testimonial {
  margin: 2em 0; }
  .main .testimonial.green {
    background-color: #4F6D2D;
    color: #ffffff; }
    .main .testimonial.green blockquote:before {
      opacity: 0.3; }
    .main .testimonial.green blockquote:after {
      opacity: 0.3; }
  .main .testimonial.purple blockquote:before {
    color: #774055; }
  .main .testimonial.purple blockquote:after {
    color: #774055; }
  .main .testimonial blockquote {
    position: relative;
    font-style: italic;
    max-width: 80%;
    margin: auto;
    font-size: 1.2em;
    padding: 4rem 0; }
    @media all and (min-width: 1280px) {
      .main .testimonial blockquote {
        font-size: 21px; } }
    .main .testimonial blockquote:before {
      position: absolute;
      font-size: 4em;
      font-weight: bold;
      content: "\201C";
      top: -0.6rem;
      left: -1rem;
      font-family: "bitter";
      font-style: normal; }
      @media all and (min-width: 480px) {
        .main .testimonial blockquote:before {
          left: -1.8rem;
          top: -0.2rem; } }
      @media all and (min-width: 640px) {
        .main .testimonial blockquote:before {
          left: -2.5rem;
          top: -0.8rem;
          font-size: 6.5rem; } }
      @media all and (min-width: 800px) {
        .main .testimonial blockquote:before {
          left: -3.5rem;
          top: 0.6rem; } }
      @media all and (min-width: 960px) {
        .main .testimonial blockquote:before {
          left: -3.2rem; } }
      @media all and (min-width: 1280px) {
        .main .testimonial blockquote:before {
          left: -3.8rem; } }
      @media all and (min-width: 1440px) {
        .main .testimonial blockquote:before {
          left: -4rem; } }
    .main .testimonial blockquote:after {
      position: absolute;
      font-size: 4em;
      font-weight: bold;
      content: "\201D";
      font-family: "bitter";
      font-style: normal;
      right: -0.2rem;
      bottom: -2rem; }
      @media all and (min-width: 640px) {
        .main .testimonial blockquote:after {
          right: -1rem;
          bottom: -2.5rem;
          font-size: 6.5rem; } }
      @media all and (min-width: 1600px) {
        .main .testimonial blockquote:after {
          right: -3rem; } }
  .main .testimonial .testimonial-name {
    display: block;
    text-align: right;
    margin-right: 4em;
    padding-bottom: 2em; }

.main .popup-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  @media all and (min-width: 800px) {
    .main .popup-gallery {
      justify-content: flex-start; } }
  .main .popup-gallery a {
    margin: 0.05em 0;
    width: calc(50% - 0.2rem); }
    @media all and (min-width: 640px) {
      .main .popup-gallery a {
        margin: 0.3em 0;
        width: calc(50% - 0.5rem); } }
    @media all and (min-width: 800px) {
      .main .popup-gallery a {
        width: calc(33% - 1rem);
        margin-right: 1rem; }
        .main .popup-gallery a:nth-of-type(3) {
          margin-right: 0; } }
    @media all and (min-width: 1280px) {
      .main .popup-gallery a {
        width: calc(33% - 0.6rem); } }
    @media all and (min-width: 1440px) {
      .main .popup-gallery a {
        width: calc(33% - 1rem);
        margin: 0.7rem 1rem 0 0; } }
    .main .popup-gallery a img {
      margin: 0;
      width: 100%; }

@media all and (min-width: 960px) {
  .sidebar .wrap {
    margin: 0; } }

.sidebar h4 {
  color: #ffffff;
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 10px;
  left: -25px;
  top: -20px;
  font-weight: 400;
  margin: 0;
  font-size: 20px; }
  @media all and (min-width: 800px) {
    .sidebar h4 {
      top: -25px; } }
  @media all and (min-width: 1120px) {
    .sidebar h4 {
      left: -30px; } }
  @media all and (min-width: 1280px) {
    .sidebar h4 {
      padding: 10px 18px;
      top: -30px; } }
  @media all and (min-width: 640px) {
    .sidebar h4 {
      font-size: 28px; } }

.sidebar .sidebar-section {
  background-color: #F2F2F2;
  position: relative;
  margin: auto;
  max-width: 370px;
  margin-bottom: 4em; }
  @media all and (min-width: 960px) {
    .sidebar .sidebar-section {
      margin: 0 0 7em 30px; } }
  .sidebar .sidebar-section ul {
    padding: 3.5em 2em 2em; }
    .sidebar .sidebar-section ul li {
      list-style-type: none;
      position: relative;
      padding-left: 2em;
      margin-bottom: 0.5em; }

.sidebar .sidebar-case-studies h4 {
  background-color: #4F6D2D;
  padding: 10px 15px; }

.sidebar .sidebar-case-studies ul li:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  background-size: cover;
  height: 20px;
  width: 20px;
  background-image: url("../../content/images/leaf-green.png"); }

.sidebar .sidebar-case-studies ul li a {
  text-decoration: none;
  color: #000000; }
  .sidebar .sidebar-case-studies ul li a:hover {
    text-decoration: underline; }

.sidebar .sidebar-services h4 {
  background-color: #774055;
  padding: 10px 20px; }

.sidebar .sidebar-services ul li:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  background-size: cover;
  height: 20px;
  width: 20px;
  background-image: url("../../content/images/leaf-purple.png"); }

.sidebar .sidebar-services ul li a {
  text-decoration: none;
  color: #000000; }
  .sidebar .sidebar-services ul li a:hover {
    text-decoration: underline; }

.sidebar .sidebar-quote {
  margin-bottom: 0;
  padding-bottom: 4em; }
  @media all and (min-width: 480px) {
    .sidebar .sidebar-quote {
      padding-bottom: 5em; } }
  @media all and (min-width: 960px) {
    .sidebar .sidebar-quote {
      padding-bottom: 4em; } }
  @media all and (min-width: 1280px) {
    .sidebar .sidebar-quote {
      padding-bottom: 5em; } }
  .sidebar .sidebar-quote h4 {
    background-color: #4F6D2D;
    padding: 10px 15px; }
  .sidebar .sidebar-quote p {
    margin: 0;
    padding: 4em 1em 3em; }
    @media all and (min-width: 480px) {
      .sidebar .sidebar-quote p {
        padding: 3.5em 2em 2em; } }
  .sidebar .sidebar-quote a {
    font-weight: 700;
    display: inline-block;
    border: 2px solid;
    padding: 0.6em 2.6em 0.6em 0.9em;
    border-radius: 5px;
    text-decoration: none;
    text-transform: uppercase;
    color: #4F6D2D;
    position: relative;
    margin-right: 1em;
    float: right;
    font-size: 14px; }
    @media all and (min-width: 480px) {
      .sidebar .sidebar-quote a {
        margin-right: 2em;
        font-size: 16px; } }
    @media all and (min-width: 960px) {
      .sidebar .sidebar-quote a {
        margin-right: 1em; } }
    @media all and (min-width: 1280px) {
      .sidebar .sidebar-quote a {
        margin-right: 2em; } }
    .sidebar .sidebar-quote a:after {
      display: inline-block;
      content: " ";
      height: 24px;
      width: 24px;
      background-size: contain;
      vertical-align: middle;
      margin-left: 8px;
      position: absolute;
      background-image: url("../../content/images/arrow-green.png"); }
    .sidebar .sidebar-quote a:hover {
      text-decoration: underline; }

.sidebar .sidebar-contact ul li:before {
  display: inline-block;
  content: "";
  position: absolute;
  background-size: cover;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  left: 0rem;
  top: 0.2rem; }

.sidebar .sidebar-contact ul li a {
  color: #000000;
  text-decoration: none;
  display: block; }
  .sidebar .sidebar-contact ul li a:hover {
    text-decoration: underline; }

.sidebar .sidebar-contact .email:before {
  background-image: url("../../content/images/email.png"); }

.sidebar .sidebar-contact .phone:before {
  background-image: url("../../content/images/phone.png"); }

.sidebar .sidebar-contact .address:before {
  background-image: url("../../content/images/map-marker.png"); }

.footer {
  margin-top: 3em; }
  .footer:before {
    display: block;
    content: "";
    background-image: url("../../content/images/forest_resized.png");
    background-position-y: bottom;
    background-repeat: repeat-x;
    height: 130px;
    width: 100%;
    opacity: 0.05; }
  .footer ul {
    padding: 0; }
    .footer ul a {
      text-decoration: none;
      color: inherit; }
      .footer ul a:hover {
        text-decoration: underline; }
  .footer .columns-footer {
    background-color: #F2F2F2; }
    .footer .columns-footer ul li {
      list-style-type: none; }
    .footer .columns-footer .column {
      color: #000000; }
      @media all and (min-width: 960px) {
        .footer .columns-footer .column {
          display: inline-block;
          width: 33%;
          vertical-align: top; } }
      .footer .columns-footer .column h4 {
        display: inline-block;
        color: #ffffff;
        font-size: 1.5em;
        font-weight: 400; }
        @media all and (min-width: 960px) {
          .footer .columns-footer .column h4 {
            font-size: 28px; } }
    .footer .columns-footer .column-services h4 {
      background-color: #774055;
      padding: 10px 20px; }
    .footer .columns-footer .column-services li {
      position: relative;
      padding-left: 2em; }
      .footer .columns-footer .column-services li:before {
        display: inline-block;
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        background-size: cover;
        height: 20px;
        width: 20px;
        background-image: url("../../content/images/leaf-purple.png"); }
    .footer .columns-footer .column-contact {
      margin-bottom: 3em; }
      @media all and (min-width: 960px) {
        .footer .columns-footer .column-contact {
          margin-bottom: 0; } }
      .footer .columns-footer .column-contact h4 {
        background-color: #4F6D2D;
        padding: 10px 15px; }
      .footer .columns-footer .column-contact li {
        position: relative;
        margin-left: 35px; }
        @media all and (min-width: 960px) {
          .footer .columns-footer .column-contact li {
            margin-left: 55px; } }
        .footer .columns-footer .column-contact li:before {
          display: inline-block;
          content: "";
          background-size: cover;
          height: 20px;
          width: 20px;
          position: absolute;
          left: -2.3rem;
          top: 0.2rem; }
        .footer .columns-footer .column-contact li.address:before {
          background-image: url("../../content/images/map-marker.png"); }
        .footer .columns-footer .column-contact li.email:before {
          background-image: url("../../content/images/email.png"); }
        .footer .columns-footer .column-contact li.phone:before {
          background-image: url("../../content/images/phone.png"); }
    .footer .columns-footer .column-facebook .fb_iframe_widget {
      margin-bottom: 5em; }
      @media all and (min-width: 960px) {
        .footer .columns-footer .column-facebook .fb_iframe_widget {
          margin-top: 2.3em; } }
  .footer .lower-footer {
    background-color: #E5E5E5;
    height: 14rem;
    text-align: center; }
    @media all and (min-width: 640px) {
      .footer .lower-footer {
        height: 12rem; } }
    .footer .lower-footer ul {
      margin: auto;
      padding-top: 2em;
      max-width: 500px; }
      @media all and (min-width: 640px) {
        .footer .lower-footer ul {
          padding-top: 60px; } }
      .footer .lower-footer ul li {
        list-style-type: none;
        display: block; }
        @media all and (min-width: 640px) {
          .footer .lower-footer ul li {
            display: inline-block; } }
        .footer .lower-footer ul li:not(:last-child):after {
          display: inline-block;
          content: "·";
          margin: 0 0.1em 0 0.3em; }

.main-full .home-banner {
  background-image: url("../../content/images/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: calc(85% + 10em);
  margin-top: 10px;
  position: relative; }
  @media all and (min-width: 480px) {
    .main-full .home-banner {
      background-position-x: calc(85% + 6em); } }
  @media all and (min-width: 1120px) {
    .main-full .home-banner {
      background-position-x: calc(85% + 2em); } }
  @media all and (min-width: 1280px) {
    .main-full .home-banner {
      background-position-x: center; } }
  @media all {
    .main-full .home-banner {
      height: 460px; } }
  .main-full .home-banner h2 {
    color: #ffffff;
    background-color: #774055;
    display: inline-block;
    margin: 19rem 0 0;
    padding: 0.5rem;
    font-size: 22px; }
    @media all and (min-width: 480px) {
      .main-full .home-banner h2 {
        font-size: 30px;
        margin-top: 18.5rem; } }
    @media all and (min-width: 640px) {
      .main-full .home-banner h2 {
        font-size: 35px; } }
    @media all and (min-width: 960px) {
      .main-full .home-banner h2 {
        font-size: 40px;
        margin-top: 15.5rem; } }

.main-full .home-services {
  margin: 0 auto 7em; }
  @media all and (min-width: 800px) {
    .main-full .home-services {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch; } }
  .main-full .home-services .home-services-column {
    position: relative; }
    @media all and (min-width: 800px) {
      .main-full .home-services .home-services-column {
        display: inline-block;
        width: calc(50% - 2rem); } }
    @media all and (min-width: 960px) {
      .main-full .home-services .home-services-column {
        width: calc(50% - 2.5rem); } }
    @media all and (min-width: 1280px) {
      .main-full .home-services .home-services-column {
        width: calc(50% - 3rem); } }
    .main-full .home-services .home-services-column.green {
      margin-top: 6em; }
      .main-full .home-services .home-services-column.green li:before {
        display: inline-block;
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        background-size: cover;
        height: 20px;
        width: 20px;
        background-image: url("../../content/images/leaf-green.png"); }
    .main-full .home-services .home-services-column.purple {
      margin-top: 8em; }
      @media all and (min-width: 800px) {
        .main-full .home-services .home-services-column.purple {
          float: right;
          margin-top: 6em; } }
      .main-full .home-services .home-services-column.purple li:before {
        display: inline-block;
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        background-size: cover;
        height: 20px;
        width: 20px;
        background-image: url("../../content/images/leaf-purple.png"); }
    .main-full .home-services .home-services-column h3 {
      color: #ffffff;
      display: inline-block;
      position: absolute;
      margin: 0;
      padding: 10px;
      left: -25px;
      top: -20px;
      font-weight: 400;
      font-size: 20px; }
      @media all and (min-width: 800px) {
        .main-full .home-services .home-services-column h3 {
          top: -25px; } }
      @media all and (min-width: 1120px) {
        .main-full .home-services .home-services-column h3 {
          left: -30px; } }
      @media all and (min-width: 1280px) {
        .main-full .home-services .home-services-column h3 {
          padding: 10px 18px;
          top: -30px; } }
      @media all and (min-width: 480px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 30px;
          top: -25px; } }
      @media all and (min-width: 640px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 35px;
          top: -30px; } }
      @media all and (min-width: 800px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 25px;
          top: -25px; } }
      @media all and (min-width: 960px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 30px;
          top: -27px; } }
      @media all and (min-width: 1120px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 35px;
          top: -30px; } }
      @media all and (min-width: 1280px) {
        .main-full .home-services .home-services-column h3 {
          font-size: 40px; } }
      .main-full .home-services .home-services-column h3.green {
        background-color: #4F6D2D; }
      .main-full .home-services .home-services-column h3.purple {
        background-color: #774055; }
    .main-full .home-services .home-services-column ul {
      padding: 0;
      margin: 1em 0 2em; }
      .main-full .home-services .home-services-column ul li {
        list-style-type: none;
        position: relative;
        padding-left: 2em;
        margin-bottom: 0.5em; }
    .main-full .home-services .home-services-column .link-button-wrapper {
      position: absolute;
      right: 0; }
      @media all and (min-width: 800px) {
        .main-full .home-services .home-services-column .link-button-wrapper {
          bottom: -2rem; } }
      .main-full .home-services .home-services-column .link-button-wrapper .link-button {
        font-weight: 700;
        display: inline-block;
        border: 2px solid;
        padding: 0.6em 2.6em 0.6em 0.9em;
        border-radius: 5px;
        text-decoration: none;
        text-transform: uppercase;
        position: relative; }
        .main-full .home-services .home-services-column .link-button-wrapper .link-button:hover {
          text-decoration: underline; }
        .main-full .home-services .home-services-column .link-button-wrapper .link-button.purple {
          color: #774055; }
          .main-full .home-services .home-services-column .link-button-wrapper .link-button.purple:after {
            display: inline-block;
            content: " ";
            height: 24px;
            width: 24px;
            background-size: contain;
            vertical-align: middle;
            margin-left: 8px;
            position: absolute;
            background-image: url("../../content/images/arrow-purple.png"); }
        .main-full .home-services .home-services-column .link-button-wrapper .link-button.green {
          color: #4F6D2D; }
          .main-full .home-services .home-services-column .link-button-wrapper .link-button.green:after {
            display: inline-block;
            content: " ";
            height: 24px;
            width: 24px;
            background-size: contain;
            vertical-align: middle;
            margin-left: 8px;
            position: absolute;
            background-image: url("../../content/images/arrow-green.png"); }

.main-full .home-testimonials {
  background-image: url("../../content/images/background-logo.png");
  background-color: rgba(79, 109, 45, 0.9);
  background-position: right bottom;
  background-repeat: no-repeat;
  color: #ffffff; }
  .main-full .home-testimonials h3 {
    color: #4F6D2D;
    background-color: #ffffff;
    display: inline-block;
    padding: 0.4em 0.6em;
    font-weight: 400;
    margin: 3rem 0 1rem; }
    @media all and (min-width: 640px) {
      .main-full .home-testimonials h3 {
        font-size: 30px; } }
    @media all and (min-width: 800px) {
      .main-full .home-testimonials h3 {
        font-size: 40px;
        margin: 3rem 0 2rem; } }
    @media all and (min-width: 1120px) {
      .main-full .home-testimonials h3 {
        margin: 4rem 0 3rem; } }
  @media all and (min-width: 800px) {
    .main-full .home-testimonials .image {
      display: inline-block;
      background-image: url("../../content/images/testimonial-image.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 160px;
      width: 160px;
      position: absolute;
      top: 11rem;
      left: -0.5rem; } }
  @media all and (min-width: 960px) {
    .main-full .home-testimonials .image {
      top: 12rem;
      left: 1rem; } }
  @media all and (min-width: 1120px) {
    .main-full .home-testimonials .image {
      left: 1rem;
      top: 14.8rem; } }
  @media all and (min-width: 1280px) {
    .main-full .home-testimonials .image {
      top: 15rem; } }
  @media all and (min-width: 1440px) {
    .main-full .home-testimonials .image {
      left: 1rem;
      top: 15rem; } }
  .main-full .home-testimonials blockquote {
    color: #ffffff;
    font-style: italic;
    font-size: 18px;
    max-width: 80%;
    margin: 2rem auto 1rem;
    position: relative; }
    @media all and (min-width: 480px) {
      .main-full .home-testimonials blockquote {
        font-size: 20px; } }
    @media all and (min-width: 800px) {
      .main-full .home-testimonials blockquote {
        max-width: 70%;
        margin: 1em 1em 1em 10em; } }
    @media all and (min-width: 960px) {
      .main-full .home-testimonials blockquote {
        font-size: 24px;
        max-width: 85%; } }
    @media all and (min-width: 1120px) {
      .main-full .home-testimonials blockquote {
        max-width: 75%; } }
    @media all and (min-width: 1280px) {
      .main-full .home-testimonials blockquote {
        font-size: 28px;
        max-width: 65%; } }
    @media all and (min-width: 1440px) {
      .main-full .home-testimonials blockquote {
        max-width: 65%;
        margin: 1em 1em 1em 11em; } }
    .main-full .home-testimonials blockquote:before {
      position: absolute;
      font-size: 4em;
      font-weight: bold;
      color: #ffffff;
      opacity: 0.2;
      content: "\201C";
      top: -0.5em;
      left: -2.5rem;
      font-family: "bitter";
      font-style: normal; }
      @media all and (min-width: 480px) {
        .main-full .home-testimonials blockquote:before {
          left: -3rem; } }
      @media all and (min-width: 800px) {
        .main-full .home-testimonials blockquote:before {
          top: -1.6rem; } }
      @media all and (min-width: 960px) {
        .main-full .home-testimonials blockquote:before {
          left: -4rem;
          top: -1.8rem; } }
      @media all and (min-width: 1280px) {
        .main-full .home-testimonials blockquote:before {
          left: -5rem;
          top: -2.1rem; } }
    .main-full .home-testimonials blockquote:after {
      position: absolute;
      font-size: 4em;
      font-weight: bold;
      color: #ffffff;
      opacity: 0.2;
      content: "\201D";
      right: -0.8rem;
      bottom: -5rem;
      font-family: "bitter";
      font-style: normal; }
      @media all and (min-width: 480px) {
        .main-full .home-testimonials blockquote:after {
          right: 1rem; } }
      @media all and (min-width: 640px) {
        .main-full .home-testimonials blockquote:after {
          right: -1rem; } }
      @media all and (min-width: 960px) {
        .main-full .home-testimonials blockquote:after {
          right: -1.5rem; } }
      @media all and (min-width: 1280px) {
        .main-full .home-testimonials blockquote:after {
          right: -2.4rem; } }
  .main-full .home-testimonials .name {
    display: inline-block;
    float: right;
    margin-top: 1em;
    margin-right: 2em; }
    @media all and (min-width: 640px) {
      .main-full .home-testimonials .name {
        margin-right: 20%; } }
    @media all and (min-width: 960px) {
      .main-full .home-testimonials .name {
        margin-right: 15%; } }
    @media all and (min-width: 1280px) {
      .main-full .home-testimonials .name {
        margin-right: 12%; } }
    .main-full .home-testimonials .name:before {
      display: inline-block;
      content: "-";
      margin-right: 0.1em; }
  .main-full .home-testimonials .buttons {
    display: inline-block;
    text-align: center;
    padding: 2.5rem 0 2rem; }
    @media all and (min-width: 640px) {
      .main-full .home-testimonials .buttons {
        display: block;
        text-align: right;
        padding: 5rem 0 3rem; } }
    @media all and (min-width: 800px) {
      .main-full .home-testimonials .buttons {
        padding: 5rem 0 4rem; } }
    @media all and (min-width: 1120px) {
      .main-full .home-testimonials .buttons {
        padding: 6rem 0 5rem; } }
    .main-full .home-testimonials .buttons a {
      font-weight: 700;
      display: inline-block;
      border: 2px solid;
      padding: 0.4rem;
      border-radius: 5px;
      text-decoration: none;
      text-transform: uppercase;
      margin: 0 2rem 2rem; }
      @media all and (min-width: 480px) {
        .main-full .home-testimonials .buttons a {
          padding: 0.6em 0.9em;
          margin: 0 4rem 2rem; } }
      @media all and (min-width: 640px) {
        .main-full .home-testimonials .buttons a {
          margin: 0 0 0 2rem; } }
      .main-full .home-testimonials .buttons a.green {
        color: #4F6D2D;
        background-color: #ffffff;
        border-color: #ffffff; }
      .main-full .home-testimonials .buttons a.white {
        color: #ffffff;
        border-color: #ffffff; }
      .main-full .home-testimonials .buttons a:hover {
        text-decoration: underline; }

.main-full .home-community h3 {
  color: #ffffff;
  background-color: #774055;
  display: inline-block;
  padding: 0.4em 0.6em;
  font-weight: 400;
  font-size: 25px;
  margin: 2em 0 1em; }
  @media all and (min-width: 480px) {
    .main-full .home-community h3 {
      font-size: 30px; } }
  @media all and (min-width: 800px) {
    .main-full .home-community h3 {
      font-size: 40px;
      margin: 4rem 0 2rem; } }
  @media all and (min-width: 960px) {
    .main-full .home-community h3 {
      margin: 4rem 0; } }

@media all and (min-width: 960px) {
  .main-full .home-community .column {
    display: inline-block;
    width: calc(50% - 2rem);
    vertical-align: top; } }

@media all and (min-width: 1280px) {
  .main-full .home-community .column {
    width: calc(50% - 1.5rem); } }

.main-full .home-community .home-community-text p {
  margin-top: 0; }

.main-full .home-community .home-community-text a {
  color: #774055;
  text-decoration: none; }
  .main-full .home-community .home-community-text a:hover {
    text-decoration: underline; }

.main-full .home-community .home-community-text ul {
  padding-left: 1em; }
  .main-full .home-community .home-community-text ul li {
    list-style-type: none;
    position: relative;
    padding-left: 2em;
    margin-bottom: 0.5em; }
    .main-full .home-community .home-community-text ul li:before {
      display: inline-block;
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      background-size: cover;
      height: 20px;
      width: 20px;
      background-image: url("../../content/images/leaf-purple.png"); }

.main-full .home-community .home-community-text .button-link {
  font-weight: 700;
  display: inline-block;
  border: 2px solid;
  padding: 0.6em 2.6em 0.6em 0.9em;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  color: #4F6D2D;
  float: right;
  margin-top: 1em;
  position: relative; }
  .main-full .home-community .home-community-text .button-link:hover {
    text-decoration: underline; }
  .main-full .home-community .home-community-text .button-link:after {
    display: inline-block;
    content: " ";
    height: 24px;
    width: 24px;
    background-size: contain;
    vertical-align: middle;
    margin-left: 8px;
    position: absolute;
    background-image: url("../../content/images/arrow-green.png"); }

.main-full .home-community .home-community-gallery {
  margin-top: 7em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start; }
  @media all and (min-width: 640px) {
    .main-full .home-community .home-community-gallery {
      justify-content: space-around; } }
  @media all and (min-width: 960px) {
    .main-full .home-community .home-community-gallery {
      margin-top: 0;
      float: right; } }
  .main-full .home-community .home-community-gallery a {
    width: calc(50% - 1rem);
    margin: 0.125rem 0; }
    @media all and (min-width: 640px) {
      .main-full .home-community .home-community-gallery a {
        margin: 0.25rem 0; } }
    @media all and (min-width: 1280px) {
      .main-full .home-community .home-community-gallery a {
        margin: 0.5rem 0;
        width: calc(50% - 1.5rem); } }
    .main-full .home-community .home-community-gallery a img {
      width: 100%; }

.grid-wrap {
  margin-left: 25px; }
  @media all and (min-width: 1120px) {
    .grid-wrap {
      margin-left: 30px; } }

.nav-grid {
  margin: 0 auto 4em; }
  @media all and (min-width: 640px) {
    .nav-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch; } }
  .nav-grid .nav-item {
    position: relative;
    margin-top: 6em;
    margin-bottom: 2em; }
    @media all and (min-width: 640px) {
      .nav-grid .nav-item {
        display: inline-block;
        width: calc(50% - 2.5rem); } }
    @media all and (min-width: 1120px) {
      .nav-grid .nav-item {
        width: calc(33% - 2.6rem); } }
    .nav-grid .nav-item h3 {
      color: #ffffff;
      display: inline-block;
      position: absolute;
      margin: 0;
      padding: 10px;
      left: -25px;
      top: -20px;
      font-weight: 400;
      font-size: 1.1em; }
      @media all and (min-width: 800px) {
        .nav-grid .nav-item h3 {
          top: -25px; } }
      @media all and (min-width: 1120px) {
        .nav-grid .nav-item h3 {
          left: -30px; } }
      @media all and (min-width: 1280px) {
        .nav-grid .nav-item h3 {
          padding: 10px 18px;
          top: -30px; } }
      @media all and (min-width: 480px) {
        .nav-grid .nav-item h3 {
          font-size: 28px; } }
      @media all and (min-width: 640px) {
        .nav-grid .nav-item h3 {
          font-size: 1.1em; } }
      @media all and (min-width: 800px) {
        .nav-grid .nav-item h3 {
          font-size: 1.4em; } }
      @media all and (min-width: 960px) {
        .nav-grid .nav-item h3 {
          font-size: 24px; } }
      @media all and (min-width: 1120px) {
        .nav-grid .nav-item h3 {
          font-size: 22px; } }
      @media all and (min-width: 1280px) {
        .nav-grid .nav-item h3 {
          padding: 15px 20px; } }
      @media all and (min-width: 1440px) {
        .nav-grid .nav-item h3 {
          font-size: 28px; } }
      .nav-grid .nav-item h3 a {
        text-decoration: none;
        color: #ffffff; }
        .nav-grid .nav-item h3 a:hover {
          text-decoration: underline; }
    .nav-grid .nav-item img {
      display: block; }
    .nav-grid .nav-item.top {
      margin-top: 2em; }
    @media all and (min-width: 640px) {
      .nav-grid .nav-item.top-2 {
        margin-top: 2em; } }
    @media all and (min-width: 1120px) {
      .nav-grid .nav-item.top-3 {
        margin-top: 2em; } }
    .nav-grid .nav-item .link-button-wrapper {
      position: absolute;
      right: 0;
      bottom: -50px; }
      .nav-grid .nav-item .link-button-wrapper .link-button {
        font-weight: 700;
        display: inline-block;
        border: 2px solid;
        padding: 0.6em 2.6em 0.6em 0.9em;
        border-radius: 5px;
        text-decoration: none;
        text-transform: uppercase;
        position: relative; }
        .nav-grid .nav-item .link-button-wrapper .link-button:hover {
          text-decoration: underline; }
    .nav-grid .nav-item.purple h3 {
      background-color: #774055; }
    .nav-grid .nav-item.purple .link-button {
      border: 2px solid #774055;
      color: #774055; }
      .nav-grid .nav-item.purple .link-button:after {
        display: inline-block;
        content: " ";
        height: 24px;
        width: 24px;
        background-size: contain;
        vertical-align: middle;
        margin-left: 8px;
        position: absolute;
        background-image: url("../../content/images/arrow-purple.png"); }
    @media all and (min-width: 640px) {
      .nav-grid .nav-item.purple.even h3 {
        background-color: #4F6D2D; }
      .nav-grid .nav-item.purple.even .link-button {
        border: 2px solid #4F6D2D;
        color: #4F6D2D; }
        .nav-grid .nav-item.purple.even .link-button:after {
          display: inline-block;
          content: " ";
          height: 24px;
          width: 24px;
          background-size: contain;
          vertical-align: middle;
          margin-left: 8px;
          position: absolute;
          background-image: url("../../content/images/arrow-green.png"); } }
    @media all and (min-width: 1120px) {
      .nav-grid .nav-item.purple.even h3 {
        background-color: #774055; }
      .nav-grid .nav-item.purple.even .link-button {
        border: 2px solid #774055;
        color: #774055; }
        .nav-grid .nav-item.purple.even .link-button:after {
          display: inline-block;
          content: " ";
          height: 24px;
          width: 24px;
          background-size: contain;
          vertical-align: middle;
          margin-left: 8px;
          position: absolute;
          background-image: url("../../content/images/arrow-purple.png"); } }
    .nav-grid .nav-item.green h3 {
      background-color: #4F6D2D; }
    .nav-grid .nav-item.green .link-button {
      border: 2px solid #4F6D2D;
      color: #4F6D2D; }
      .nav-grid .nav-item.green .link-button:after {
        display: inline-block;
        content: " ";
        height: 24px;
        width: 24px;
        background-size: contain;
        vertical-align: middle;
        margin-left: 8px;
        position: absolute;
        background-image: url("../../content/images/arrow-green.png"); }
    @media all and (min-width: 640px) {
      .nav-grid .nav-item.green.even h3 {
        background-color: #774055; }
      .nav-grid .nav-item.green.even .link-button {
        border: 2px solid #774055;
        color: #774055; }
        .nav-grid .nav-item.green.even .link-button:after {
          display: inline-block;
          content: " ";
          height: 24px;
          width: 24px;
          background-size: contain;
          vertical-align: middle;
          margin-left: 8px;
          position: absolute;
          background-image: url("../../content/images/arrow-purple.png"); } }
    @media all and (min-width: 1120px) {
      .nav-grid .nav-item.green.even h3 {
        background-color: #4F6D2D; }
      .nav-grid .nav-item.green.even .link-button {
        border: 2px solid #4F6D2D;
        color: #4F6D2D; }
        .nav-grid .nav-item.green.even .link-button:after {
          display: inline-block;
          content: " ";
          height: 24px;
          width: 24px;
          background-size: contain;
          vertical-align: middle;
          margin-left: 8px;
          position: absolute;
          background-image: url("../../content/images/arrow-green.png"); } }

.figure,
.wp-caption {
  margin: 1rem 0;
  text-align: center; }
  .figure img,
  .wp-caption img {
    display: block;
    margin: 0 auto; }
  .figure blockquote + .caption,
  .wp-caption blockquote + .caption {
    text-align: right; }
  .figure > .caption,
  .figure > .wp-caption-text,
  .wp-caption > .caption,
  .wp-caption > .wp-caption-text {
    font-size: 0.875em;
    margin: 0.5em 0;
    text-align: center; }

.alignnone {
  margin: 0; }

.aligncenter,
.aligncenter.wp-caption {
  display: block;
  margin: 1em auto; }

.alignright,
.alignright.wp-caption {
  float: right;
  margin: 1em 0 1em 1em; }

.alignleft,
.alignleft.wp-caption {
  float: left;
  margin: 1em 1em 1em 0; }

.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
