.path {
    margin-top:1em;
    margin-left:7em;

    @include media-query(nano) {
        margin-left:10em;
    }

    @include media-query(micro) {
        margin-left:13em;
    }

    a {
        color:$purple;
        text-decoration:none;

        &:hover {
            text-decoration:underline;
        }
    }
}

.main {
    h3 {
        color:$purple;
        font-weight:400;
        margin: 1em 0 0.5em;

        @include media-query(kilo) {
            margin:0.75em 0 0.5em;
        }        
    }

    img {
        margin:1em 0;
    }

    a {
        text-decoration:none;
        color:$purple;
        display:inline;
        margin:1em 0;

        &:hover {
            text-decoration:underline;
        }
    }

    .image-block {
        img {
            width:100%;

            @include media-query(micro) {
                width: calc(50% - 1em);
            }

            &:last-of-type {
                float:right;
            }
        }
    }

    //about page profiles
    .person {
        text-align:center;

        @include media-query(milli) {
            margin:2em 0 3em;
        }

        @include media-query(normal) {
            margin: 3em 0;
        }

        .person-image {
            margin:3em 0;
            text-align:center;
            position:relative;
            display:inline-block;

            @include media-query(milli) {
                width:25%;
                max-width:200px;
                margin:1rem 2rem 0 0;
                float:left;
                text-align:left;
            }

            img {
                margin:0;
                                
            }

            &:before {
                content:" ";
                display:inline-block;
                width:calc(100% + 1rem);
                height:calc(98% + 1rem);
                border-radius:50%;
                position:absolute;
                top:-0.55rem;
                bottom:0;
                left:-0.47rem;
                right:0;
                z-index:-1;
                background-color:#ffffff;
                box-shadow: 0 0 10px 2px rgba(0,0,0,0.4);
            }
        }

        .person-profile {
            text-align:left;

            @include media-query(milli) {
                display:inline-block;
                width:calc(70% - 0.5em);
                vertical-align:top;
            }

            p:first-of-type {
                font-size:21px;
            }

            h4 {
                display:inline-block;
                color:#ffffff;
                font-weight:400;
                font-size:28px;
                padding:0.6rem 1.2rem;
                margin:0;

                &.green {
                    background-color:$green;
                }

                &.purple {
                    background-color:$purple;
                }
            }
        }
    }

    //contact page form
    .contact-form {
        margin-top:1em;

        .field {
            margin-bottom:10px;

            .text-label {
                display:inline-block;
                width:100px;
                color:$purple;
                font-weight:700;
            }

            .text-input {
                margin-left: 10px;
                width: calc(70% - 10px);
                margin-bottom:10px;
            }

            &.submit {
                margin-top:20px;
            }

            textarea {
                height:100px;
            }

            .button {
                color:$green;
                background-color:#ffffff;
                @include arrow-button();

                &:hover {
                    text-decoration:underline;
                }
            }
        }
    }

    //all testimonials
    .testimonial {
        margin:2em 0;

        &.green {
            background-color:$green;
            color:#ffffff; 

            blockquote {
                &:before {
                    opacity:0.3;
                }

                &:after {
                    opacity:0.3;
                }
            } 
        }

        &.purple {
            blockquote {
                &:before {
                    color: $purple;
                }

                &:after {
                    color: $purple;
                }
            }
        }

        blockquote {
            position:relative;
            font-style:italic;
            max-width:80%;
            margin:auto;
            font-size:1.2em;
            padding:4rem 0;

            @include media-query(mega) {
                font-size:21px;
            }

            &:before {
                position:absolute;
                font-size:4em;
                font-weight:bold;
                content: "\201C";
                top: -0.6rem;
                left: -1rem;
                font-family:"bitter";
                font-style:normal;

                @include media-query(nano) {
                    left:-1.8rem;
                    top:-0.2rem;
                }

                @include media-query(micro) {
                    left: -2.5rem;
                    top:-0.8rem; 
                    font-size:6.5rem;
                }

                @include media-query(milli) {
                    left:-3.5rem;
                    top:0.6rem;
                }

                @include media-query(normal) {
                    left:-3.2rem;
                }

                @include media-query(mega) {
                    left:-3.8rem;
                }

                @include media-query(giga) {
                    left:-4rem;
                }
            }

            &:after {
                position:absolute;
                font-size:4em;
                font-weight:bold;
                content: "\201D";
                font-family:"bitter";
                font-style:normal;

                right: -0.2rem;
                bottom: -2rem;

                @include media-query(micro) {
                    right:-1rem;
                    bottom:-2.5rem;
                    font-size:6.5rem;
                } 

                @include media-query(tera) {
                    right:-3rem;
                }
            }
        }

        .testimonial-name {
            display:block;
            text-align:right;
            margin-right:4em;
            padding-bottom:2em;
        } 
    }

    .popup-gallery {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:flex-start;

        @include media-query(milli) {
            justify-content:flex-start;
        }

        a {
            margin:0.05em 0;
            width:calc(50% - 0.2rem);

            @include media-query(micro) {
                margin:0.3em 0;
                width:calc(50% - 0.5rem);
            }

            @include media-query(milli) {
                width:calc(33% - 1rem);
                margin-right:1rem;

                &:nth-of-type(3) {
                    margin-right:0;
                }
            }

            @include media-query(mega){
                width:calc(33% - 0.6rem);
            }

            @include media-query(giga) {
                width:calc(33% - 1rem);
                margin:0.7rem 1rem 0 0;
            }



            img {
                margin:0;
                width:100%;
            }
        }
    }
}