//
// Layout
//

// Float container
%float-container {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

// Page section wrapper
.wrap {
    @extend %float-container;
    max-width:1280px;
    margin:0 1.5rem;

    @include media-query(micro) {
        margin:0 2.5rem;
    }

    @include media-query(milli) {
        margin:0 3.5rem;
    }

    @include media-query(normal) {
        margin:0 2.5rem;
    }

    @include media-query(1380px) {
        margin:auto;
    }

    &.position-wrap {
        position:relative;
    }
}

.content {
    .main-full {
        display:block;
        width:100%;
        margin-top:4em;

        &.home {
            margin-top:0;
        }
    }

    .main {
        margin-top:4em;

        @include media-query(normal) {
            display:inline-block;
            width:calc(65% - 1.5rem);
            vertical-align:top; 
        } 

        @include media-query(mega) {
            width:calc(65% - 2.5rem);
        }     
    }

    .sidebar {
        margin-top:4em;

        @include media-query(normal) {
            display:inline-block;
            width:calc(35% - 1.5rem);
            vertical-align:top;
            margin-top:10.5em;
            float:right;
        }

        @include media-query(mega) {
            width:calc(35% - 2.5rem);
        }
    }
}