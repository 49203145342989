.header {
    .upper-header {
        height:89px;
        position:relative;
        text-align:right;

        @include media-query(micro) {
            text-align:center;
        }

        @include media-query(normal) {
            text-align:right;
        }

        &:after {
            content:"";
            position:absolute;
            background-image: url('../../content/images/forest_resized.png');
            background-repeat-y:no-repeat;
            background-position-y:bottom;
            opacity:0.05;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }

        .logo {
            display:block;
            position:absolute;
            top:15px;
            z-index:2;
            text-align:left;
            left:5px;

            @include media-query(nano) {
                top:8px;
                left:inherit;
            }

            @include media-query(micro) {
                top:-6px;
            }

            img {
                width:70%;
                @include media-query(nano) {
                    width:80%;
                }

                @include media-query(micro) {
                    width:auto;
                }
            }
        }

        ul {
            margin:0;
            padding:0.5rem 0;

            @include media-query(normal) {
                padding-top:1.5em;
            }
            
            li {
                display:block;
                font-size:21px;
                

                @include media-query(normal){
                    display:inline-block;
                }

                &:first-of-type {
                    @include media-query(normal) {
                        position:relative;
                        margin-right:1rem;
                    }

                    &:after {
                        @include media-query(normal) {
                            display:inline-block;
                            content:"·";
                            position:absolute;
                            right: -0.8rem;
                            top:0.1rem;
                        }
                    }
                }

                a {
                    color:#2f411b;
                }
            }
        }
    }

    .nav {
        background-color:$green;
        height:auto;

        @include media-query(normal){
            height:60px;
        }

        .nav-hidden {
            display:none;

            @include media-query(normal) {
                display:block;
            }
        }

        #nav-toggle {
            padding:0.5em 0;
            font-size:1.1em;
            @include media-query(normal) {
                display:none;
            }
        }

        ul {
            padding:0;
            margin:0;

            @include media-query(normal) {
                float:right;
            }

            li {
                position:relative;
                display:block;
                background-color:$green;
                padding-bottom: 0.5em;
                font-size:1.1em;

                @include media-query(milli) {
                    margin-left:0.5em;
                }

                @include media-query(normal) {
                    margin-left:1em;
                    font-size:1em;
                    padding:0;
                    display:inline-block;
                }

                @include media-query(kilo) {
                    margin-left:2em;
                }

                &:before {
                    content:"";
                    border: solid #ffffff 1px;
                    display:inline-block;
                    width:40%;
                    position:absolute;
                    right:0;

                    @include media-query(micro) {
                        right:30%;
                    }

                    @include media-query(normal) {
                        display:none;
                    }
                }

                &.active:after {
                    @include media-query(normal){
                        content:"";
                        position:absolute;
                        bottom:0;
                        left:calc(50% - 8px);
                        margin:auto;
                        border-style: solid;
                        border-width: 0 7px 8px 7px;
                        border-color: transparent transparent #ffffff transparent;
                    }
                }                
            }
        }

        a {
            color:#ffffff;
            font-weight:700;
            display:block;
            text-decoration:none;
            text-align:right;
            padding-top:0.5em;

            &#menu-toggle {
                padding:0;
            }

            @include media-query(micro) {
                text-align:center;
            } 

            @include media-query(normal) {
                padding:1.2em 0;
                height:60px;
            }  
        }
    }

    li {
        list-style-type:none;
    }

    a {
        text-decoration:none;

        &:hover {
             text-decoration:underline;
        }
    } 
}