.sidebar {
    .wrap {
        @include media-query(normal){
            margin:0;
        }
    }

    h4 {
        @include header-box();
        margin:0;
        font-size:20px;

        @include media-query(micro) {
            font-size:28px;
        }
    }

    .sidebar-section {
        background-color:$grey;
        position:relative;
        margin:auto;
        max-width:370px;
        margin-bottom:4em;

        @include media-query(normal) {
            margin: 0 0 7em 30px;
        }

        ul {
            padding: 3.5em 2em 2em;

            li {
                list-style-type:none;
                position:relative;
                padding-left:2em; 
                margin-bottom:0.5em;
            }
        }
    }

    .sidebar-case-studies {
        h4 {
            background-color:$green;
            padding:10px 15px;
        }

        ul {
            li {
                &:before {
                    @include leaf-points('green');
                }

                a {
                    text-decoration:none;
                    color:#000000;

                    &:hover {
                        text-decoration:underline;
                    }
                }
            }
        }

    }

    .sidebar-services {
        h4 {
            background-color:$purple;
            padding:10px 20px;
        }

        ul {
            li {
                &:before {
                    @include leaf-points('purple');
                }

                a {
                    text-decoration:none;
                    color:#000000;

                    &:hover {
                        text-decoration:underline;
                    }
                }
            }
        }
    }

    .sidebar-quote {
        margin-bottom:0;
        padding-bottom:4em;

        @include media-query(nano) {
            padding-bottom:5em;
        }

        @include media-query(normal) {
           padding-bottom:4em; 
        }

        @include media-query(mega) {
            padding-bottom:5em;
        }

        h4 {
            background-color:$green;
            padding:10px 15px;
        }

        p {
            margin:0;
            padding:4em 1em 3em;

            @include media-query(nano) {
                padding:3.5em 2em 2em;   
            }
        }

        a {
            @include arrow-button(0.6em 2.6em 0.6em 0.9em)
            color:$green; 
            position:relative;
            margin-right:1em;
            float:right;
            font-size:14px;

            @include media-query(nano) {
                margin-right:2em;
                font-size:16px;
            }

            @include media-query(normal) {
                margin-right:1em;
            }

            @include media-query(mega) {
                margin-right:2em;
            }

            &:after {
                @include arrow('green');
            }

            &:hover {
                text-decoration:underline;
            }
        }
    }

    .sidebar-contact {
        ul {
            li {
                &:before {
                    display:inline-block;
                    content: "";
                    position:absolute;
                    background-size:cover;
                    height:20px;
                    width:20px;
                    margin-right:10px;
                    left:0rem;
                    top:0.2rem;
                }

                a {
                    color:#000000;
                    text-decoration:none;
                    display:block;

                    &:hover {
                        text-decoration:underline;
                    }
                }
            }
        }

        .email:before {
            background-image:url('../../content/images/email.png');
        }

        .phone:before {
            background-image:url('../../content/images/phone.png');
        }

        .address:before {
            background-image:url('../../content/images/map-marker.png');
        }
    }
}